import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import ContentPrivacy from '@/components/contents/PrivacyPolicy/Content'
import getMetaImage from '@/utils/getMetaImage'

const PrivacyPage = ({ data }) => {
  const content = { ...data?.content?.data, slug: data?.content?.slug }

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      <ContentPrivacy content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query PrivacyIndex($locale: String) {
    content: prismicPrivacyPage(lang: { eq: $locale }) {
      lang
      slug: uid
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        main_title
        content_privacy {
          html
        }
      }
    }
  }
`

export default PrivacyPage
